module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-do_314a3ec901aa39a7607e519fb82279c7/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pauline Maudy","short_name":"Pauline Maudy","background_color":"#5d7368","theme_color":"#292929","start_url":"/","display":"standalone","icon":"/opt/build/repo/static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fae7bd006d5a396cf2ac979e0d621435"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.16.2_@emotion+react@11.11.4_@types+react@18.2.79_react@18.2.0__7f78cd93312a86a59f8f336f706ad9e9/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.2_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom_75ac6253ec7b6e4c47bb9c6a94ab3456/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.2.0_react@18.2.0__react@1_c87dad82f7c716a4d10656f4e5b9d54b/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
